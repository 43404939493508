// import React from "react";
// import Photo from "../svgImages/photo.png"; // Ensure this path is correct

// function AboutUs() {
//   return (
//     <div
//       className="relative h-screen flex items-center justify-center"
//       style={{
//         backgroundImage: `url(${Photo})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//       }}
//     >
//       <div className="text-center bg-black bg-opacity-50 p-4 rounded-lg">
//         <p className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl">
//           About Us
//         </p>
//         <p className="text-white mt-2 text-sm sm:text-base md:text-lg lg:text-xl">
//           We are a team of serial entrepreneurs making skilling and mentorship
//           affordable and accessible to everyone. We firmly believe that if you
//           are focused on one thing and consistently put in your effort, you'll
//           become successful. Affordability, geography, and language are no
//           longer barriers.
//         </p>
//       </div>
//     </div>
//   );
// }

// export default AboutUs;
import React from "react";
import { useState, useEffect } from "react";
import Raj from "../svgImages/Raj.png";
import Rajeev from "../svgImages/Rajeev.png";
import Footer from "./Footer";
import { ReactComponent as Linkedin } from "../svgImages/Vectorlinkedin.svg";
import DesktopPerson from "../svgImages/DesktopPerson.png";
import MobilePersons from "../svgImages/MobilePersons.png";
import link from "../svgImages/Vectorlinkedin.png";
function AboutUs() {
  const [isDesktop, setIsDesktop] = useState(true); // State to track if device is desktop or not
  const checkIsDesktop = () => {
    setIsDesktop(window.innerWidth > 768); // Adjust the width condition as needed
  };

  useEffect(() => {
    checkIsDesktop();
    window.addEventListener("resize", checkIsDesktop);
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);
  function handleRajRedirect() {
    window.open(
      "https://www.linkedin.com/in/raj-d-ba4b41133/overlay/about-this-profile/",
      "_blank"
    );
  }
  function handleRajeevRedirect() {
    window.open(
      "https://www.linkedin.com/in/raj-d-ba4b41133/overlay/about-this-profile/",
      "_blank"
    );
  }
  return (
    <>
      <div
        className="relative h-[80vh] flex flex-col p-4 justify-end gap-4 items-center"
        style={{
          backgroundImage: `url(${isDesktop ? DesktopPerson : MobilePersons})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <div className="absolute inset-0 bg-[#13505B]"></div>
        <div className="absolute inset-0 bg-black opacity-50"></div> */}
        <div className="w-[70%] text-white relative flex flex-col  gap-16">
          <div className="flex flex-col">
            <p className="text-center text-2xl md:text-3xl lg:text-4xl mb-2">
              About Us
            </p>
            <p className="mb-4 text-center text-base sm:text-base md:text-lg lg:text-xl xl:text-2xl">
              We are a team of serial entrepreneurs making skilling and
              mentorship affordable and accessible to everyone. We firmly
              believe that if you are focused on one thing and consistently put
              in your effort, you'll become successful. Affordability,
              geography, and language are no longer a barrier.
            </p>
          </div>
        </div>
      </div>
      <div className="h-[90vh] md:[100vh] lg:h-[100vh] xl:[100vh] p-4 flex flex-col gap-4 md:gap-4 lg:gap-8 xl:gap-8 items-center">
        <p className="text-[#13505B] text-2xl md:text-3xl lg:text-4xl font-semibold">
          Our Mission
        </p>
        <p className="text-[#333333] text-center w-[80%] text-sm font-normal md:text-xl lg:text-2xl lg:font-medium">
          Skilling, Mentorship and Employability solution for the next billions
          across the world.
        </p>
        <hr className="w-[60%] border-1 border-[#D6D6D6]" />
        <div className="flex flex-col items-center gap-2 md:gap-4 lg:gap-4 xl:gap-4 w-[50%]">
          <p className="text-[#13505B]  text-lg md:text-3xl lg:text-4xl font-semibold">
            MasterOne Team
          </p>
          <div className="flex p-2 flex-col md:flex-row lg:flex-row gap-8 md:gap-16 lg:gap-32">
            <div>
              <img
                src={Raj}
                alt="Raj"
                className="h-[160px] md:h-[200px] lg:h-[250px] xl:h-[250px]"
              />
              <div
                className="flex flex-row lg:flex-row justify-center items-center cursor-pointer"
                onClick={handleRajRedirect}
              >
                <Linkedin />
                <p className="text-center text-xs sm:text-sm lg:text-sm font-semibold underline text-[#13505B]">
                  Raj D
                </p>
              </div>
            </div>
            <div>
              <img
                src={Rajeev}
                alt="Rajeev"
                className="h-[160px] md:h-[200px] lg:h-[250px] xl:h-[250px]"
              />
              <div
                className="flex flex-row justify-center items-center cursor-pointer"
                onClick={handleRajeevRedirect}
              >
                <Linkedin />
                <p className="text-center text-xs sm:text-sm lg:text-sm font-semibold underline text-[#13505B]">
                  Rajeev Ranjan
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AboutUs;

// export default AboutUs;
