// import React, { useState } from "react";
// import { ReactComponent as Logo } from "../svgImages/Weblogo.svg";
// import { NavLink } from "react-router-dom";

// const Navbar = ({ username }) => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const FullscreenMenu = () => (
//     <div className="fixed top-0 left-0 h-full w-full bg-white z-50 flex flex-col items-center justify-center">
//       <button
//         className="absolute top-4 right-4"
//         onClick={() => setIsMenuOpen(false)}
//       >
//         {/* Close Icon */}
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="h-6 w-6"
//           fill="none"
//           viewBox="0 0 24 24"
//           stroke="currentColor"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M6 18L18 6M6 6l12 12"
//           />
//         </svg>
//       </button>
//       <ul className="text-center">
//         <li className="my-4">
//           <NavLink
//             to="/home"
//             className={({ isActive }) =>
//               isActive ? "border-b-4 border-[#13505B] py-1" : ""
//             }
//             onClick={() => setIsMenuOpen(false)}
//           >
//             Home
//           </NavLink>
//         </li>
//         <li className="my-4">
//           <NavLink
//             to="/about"
//             className={({ isActive }) =>
//               isActive ? "border-b-4 border-[#13505B] py-1" : ""
//             }
//             onClick={() => setIsMenuOpen(false)}
//           >
//             About us
//           </NavLink>
//         </li>
//         <li className="my-4">
//           <NavLink
//             to="/contact"
//             className={({ isActive }) =>
//               isActive ? "border-b-4 border-[#13505B] py-1" : ""
//             }
//             onClick={() => setIsMenuOpen(false)}
//           >
//             Contact us
//           </NavLink>
//         </li>
//       </ul>
//     </div>
//   );

//   return (
//     <div className="flex p-1 mb-4 justify-between items-center bg-white lg:p-4 shadow-md h-16 lg:relative -z-10">
//       <div className="ml-2 text-xll flex items-center font-semibold lg:ml-8 w-[70%]">
//         <span className="pr-4">
//           <Logo />
//         </span>
//         <div className="flex justify-center items-center">
//           <span className="text-sm md:text-base lg:text-xl">MasterOne</span>
//           <span className="text-xs md:text-sm lg:text-base">
//             -Dream Career App
//           </span>
//           {/* <div class="text-container">
//             <span class="text-xl">MasterOne</span>
//             <span class="text-md md:text-lg lg:text-xl">-Dream Career App</span>
//           </div> */}
//         </div>
//       </div>
//       {/* Menu Icon */}
//       <div className="md:hidden w-[10%] flex justify-end items-center">
//         <button onClick={() => setIsMenuOpen(true)}>
//           {/* Menu Icon */}
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-6 w-6"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M4 6h16M4 12h16m-7 6h7"
//             />
//           </svg>
//         </button>
//       </div>
//       {/* Navlinks for desktop mode */}
//       <div className="hidden md:flex w-[40%] justify-around">
//         <ul className="flex flex-col md:flex-row md:justify-around flex flex-row gap-8">
//           <li>
//             <NavLink
//               to="/home"
//               className={({ isActive }) =>
//                 isActive ? "border-b-4 border-[#13505B] py-1" : ""
//               }
//             >
//               Home
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to="/about"
//               className={({ isActive }) =>
//                 isActive ? "border-b-4 border-[#13505B] py-1" : ""
//               }
//             >
//               About us
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to="/contact"
//               className={({ isActive }) =>
//                 isActive ? "border-b-4 border-[#13505B] py-1" : ""
//               }
//             >
//               Contact us
//             </NavLink>
//           </li>
//         </ul>
//       </div>
//       {isMenuOpen && <FullscreenMenu />}
//     </div>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { ReactComponent as Logo } from "../svgImages/Weblogo.svg";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = ({ username }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavLinkClick = () => {
    setIsMenuOpen(false);
  };

  const FullscreenMenu = () => (
    <div className="fixed top-0 left-0 h-full w-full bg-white z-50 flex flex-col items-center justify-center">
      <button
        className="absolute top-4 right-4"
        onClick={() => setIsMenuOpen(false)}
      >
        {/* Close Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ul className="text-center">
        <li className="my-4 font-inter">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "border-b-4 border-[#13505B] py-1" : ""
            }
            onClick={handleNavLinkClick}
          >
            Home
          </NavLink>
        </li>
        <li className="my-4 font-inter">
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? "border-b-4 border-[#13505B] py-1" : ""
            }
            onClick={handleNavLinkClick}
          >
            About us
          </NavLink>
        </li>
        <li className="my-4 font-inter">
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? "border-b-4 border-[#13505B] py-1" : ""
            }
            onClick={handleNavLinkClick}
          >
            Contact us
          </NavLink>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="flex p-1  justify-between items-center bg-white lg:p-4  shadow-md h-16 lg:relative">
      <div className="ml-2 text-xll flex items-center font-semibold lg:ml-8 w-[80%]">
        <span className="pr-4">
          <Logo />
        </span>
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <span className="text-sm md:text-base font-inter lg:text-xl text-[#13505B]">
            MasterOne
          </span>
          <span className="text-xs md:text-sm font-inter lg:text-base text-[#13505B]">
            -Dream Career App
          </span>
        </div>
      </div>
      {/* Menu Icon */}
      <div className="md:hidden w-[10%] flex justify-end items-center">
        <button onClick={() => setIsMenuOpen(true)}>
          {/* Menu Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      {/* Navlinks for desktop mode */}
      <div className="hidden md:flex w-[40%] justify-around">
        <ul className="flex flex-col md:flex-row md:justify-around flex flex-row gap-8">
          <li className="font-inter">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "border-b-4 border-[#13505B] py-1" : ""
              }
            >
              Home
            </NavLink>
          </li>
          <li
            className="font-inter"
            // onClick={() => {
            //   navigate("about");
            // }}
          >
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? "border-b-4 border-[#13505B] py-1" : ""
              }
              onClick={() => console.log("NavLink clicked")}
            >
              About us
            </NavLink>
          </li>
          <li className="font-inter">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "border-b-4 border-[#13505B] py-1" : ""
              }
            >
              Contact us
            </NavLink>
          </li>
        </ul>
      </div>
      {isMenuOpen && <FullscreenMenu />}
    </div>
  );
};

export default Navbar;
