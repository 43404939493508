import React from "react";
import Footer from "./Footer";

function Terms() {
  return (
    <>
      <div className="p-4 text-sm md:text-base lg:text-lg">
        <p className="font-semibold text-xl mb-4">
          Welcome to Terms & Conditions of MasterOne.
        </p>
        <p className="mb-4">
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the MasterOne mobile application
          (the "App").
        </p>
        <p className="mb-4">
          By accessing or using the App, you agree to be bound by these Terms.
          If you disagree with any part of the terms, then you may not access
          the App.
        </p>

        <div className="mb-8">
          <p className="font-semibold">1. Use of the App</p>
          <div className="ml-4">
            <ul className="list-disc ml-8">
              <li>You must be at least 13 years old to use the App.</li>
              <li>
                You agree to use the App solely for lawful purposes and in
                compliance with all applicable laws and regulations.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">2. User Accounts</p>
          <div className="ml-4">
            <p>
              You may be required to create an account to access certain
              features of the App. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">3. Intellectual Property</p>
          <div className="ml-4">
            <ul className="list-disc ml-8">
              <li>
                The App and its original content, features, and functionality
                are owned by MasterOne and are protected by international
                copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws.
              </li>
              <li>
                The App uses YouTube API to fetch educational content in
                accordance with the terms & conditions laid by YouTube.
                MasterOne redirects its users to YouTube player without
                affecting any business of YouTube or its creator.
              </li>
              <li>
                The app also uses third-party educational content and redirects
                its users to the original source.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">4. User Content</p>
          <div className="ml-4">
            <p>
              By using the App, you grant us the right to use, reproduce,
              modify, adapt, publish, translate, distribute, and display any
              content you submit or post within the App. You represent and
              warrant that you own or have the necessary rights to such content.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">5. Prohibited Activities</p>
          <div className="ml-4">
            <p>
              You agree not to engage in any of the following activities while
              using the App:
            </p>
            <ul className="list-disc ml-8">
              <li>Violating any laws or regulations</li>
              <li>
                Interfering with or disrupting the operation of the App or
                servers
              </li>
              <li>
                Attempting to gain unauthorized access to any part of the App
              </li>
              <li>Harassing, abusing, or harming another user</li>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">6. Limitation of Liability</p>
          <div className="ml-4">
            <p>
              In no event shall MasterOne be liable for any indirect,
              incidental, special, consequential, or punitive damages, including
              but not limited to loss of profits, data, or use, arising out of
              or in connection with your use of the App.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">7. Termination</p>
          <div className="ml-4">
            <p>
              We reserve the right to terminate or suspend your access to the
              App at any time without prior notice or liability for any reason,
              including without limitation if you breach these Terms.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">8. Governing Law</p>
          <div className="ml-4">
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of India, without regard to its conflict of law provisions.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">9. Changes to These Terms</p>
          <div className="ml-4">
            <p>
              We reserve the right to modify or replace these Terms at any time.
              If a revision is material, we will provide at least 30 days'
              notice prior to any new terms taking effect. What constitutes a
              material change will be determined at our sole discretion.
            </p>
          </div>
        </div>

        <div>
          <p className="font-semibold">10. Contact Us</p>
          <div className="ml-4">
            <p>
              If you have any questions about these Terms, please contact us at
              support@masterone.app
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Terms;
