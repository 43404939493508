// import React from "react";
// import Contact from "../svgImages/Contactus.png";
// import Footer from "./Footer";
// function ContactUs() {
//   return (
//     <div>
//       <div>
//         <img src={Contact} alt="contactus" />
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default ContactUs;
import React from "react";
import { useState, useEffect } from "react";
import Contact from "../svgImages/Contactus.png";
import Footer from "./Footer";
import MobileContact from "../svgImages/MobileContact.png";

// function ContactUs() {
//   return (
//     <>
//       <div
//         className="relative h-screen bg-no-repeat bg-cover bg-center flex flex-col justify-center items-center"
//         style={{ backgroundImage: `url(${Contact})` }}
//       >
//         <div className=" lg:w-[50%]   text-center flex flex-col gap-16 bg-opacity-75 p-4 rounded">
//           <p className="text-white lg:text-4xl lg:font-semibold">Contact Us</p>
//           <div>
//             <p className="text-white flex flex-row justify-center gap-2 items-center">
//               <p className="lg:text-2xl lg:font-semibold">Contact:</p>
//               <p className="lg:text-xl">support@masterone.app</p>
//             </p>
//           </div>

//           <p className="text-white flex flex-row justify-center gap-2 items-start">
//             <p className="lg:text-2xl lg:font-semibold">Address:</p>
//             <p className="lg:text-xl text-start">
//               Second and Third Floor, 235, Indranagar, 13th Cross Park, Stage 2,
//               Indranagar Double Road, Binnamangala, Bengaluru, Bengaluru Urban,
//               Karnataka, 560038.
//             </p>
//           </p>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// }
function ContactUs() {
  const [isDesktop, setIsDesktop] = useState(true); // State to track if device is desktop or not
  const checkIsDesktop = () => {
    setIsDesktop(window.innerWidth > 768); // Adjust the width condition as needed
  };

  useEffect(() => {
    checkIsDesktop();
    window.addEventListener("resize", checkIsDesktop);
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);
  return (
    <>
      <div
        className="relative h-[100vh] bg-no-repeat bg-cover bg-center flex flex-col justify-center items-center"
        style={{
          backgroundImage: `url(${isDesktop ? Contact : MobileContact})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="lg:w-[50%] text-center flex flex-col gap-16 bg-opacity-75 p-4 rounded">
          <p className="text-white lg:text-4xl md:text-3xl text-2xl font-semibold">
            Contact Us
          </p>
          <div>
            <p className="text-white flex flex-row justify-center gap-2 items-center">
              <p className="lg:text-2xl md:text-xl text-lg font-semibold">
                Contact:
              </p>
              <p className="lg:text-xl md:text-lg text-base">
                support@masterone.app
              </p>
            </p>
          </div>

          <p className="text-white flex flex-row justify-center gap-2 items-start">
            <p className="lg:text-2xl md:text-xl text-lg font-semibold">
              Address:
            </p>
            <p className="lg:text-xl md:text-lg text-base text-start">
              <strong>LNEBHARAT PRIVATE LIMITED</strong><br/>
              Second and Third Floor, 235, Indranagar, 13th Cross Park, Stage 2,
              Indranagar Double Road, Binnamangala, Bengaluru, Bengaluru Urban,
              Karnataka, 560038.
            </p>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;
