import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import ContentScreen from "./components/ContentScreen";
import Footer from "./components/Footer";
import Policies from "./components/Policies";
import Terms from "./components/Terms";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
// import AboutUs from "./components/AboutUs"; // Assuming you have this component
// import ContactUs from "./components/ContactUs"; // Assuming you have this component
import NotFound from "./components/NotFound";
function App() {
  return (
    <BrowserRouter>
      <div className="">
        <Navbar />
        <Routes>
          <Route path="/" element={<ContentScreen />} />
          {/* <Route path="/home" element={<ContentScreen />} /> */}
          <Route path="/privacy-policy" element={<Policies />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
