import React from "react";
import { useState, useEffect } from "react";
// import { ReactComponent as PlayStore } from "../svgImages/Playstore.svg";
// import { ReactComponent as AppStore } from "../svgImages/AppStore.svg";
import DesignComponent from "../svgImages/DesignComponent";
import Footer from "./Footer";
import Playstore from "../svgImages/Playstore";
import AppStore from "../svgImages/AppStore";

function ContentScreen() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMeidumScreen, setIsMeidumScreen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isExtraLargeScreen, setIsExtraLargeScreen] = useState(false);
  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 760); // Assuming 'small' is any screen size less than 1024px
    };
    const checkMeidumScreenSize = () => {
      setIsMeidumScreen(window.innerWidth > 760 && window.innerWidth < 1024); // Assuming 'small' is any screen size less than 1024px
    };
    const checkLargeScreenSize = () => {
      setIsLargeScreen(window.innerWidth > 1024 && window.innerWidth < 1280); // Assuming 'small' is any screen size less than 1024px
    };
    const checkExtraLargeScreenSize = () => {
      setIsExtraLargeScreen(window.innerWidth > 1280); // Assuming 'small' is any screen size less than 1024px
    };
    // Check on mount
    checkScreenSize();
    checkMeidumScreenSize();
    checkLargeScreenSize();
    checkExtraLargeScreenSize();

    // Add event listener
    window.addEventListener("resize", checkScreenSize);
    window.addEventListener("resize", checkMeidumScreenSize);
    window.addEventListener("resize", checkLargeScreenSize);
    window.addEventListener("resize", checkExtraLargeScreenSize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", checkScreenSize);
      window.removeEventListener("resize", checkMeidumScreenSize);
      window.removeEventListener("resize", checkLargeScreenSize);
      window.removeEventListener("resize", checkExtraLargeScreenSize);
    };
  }, []);

  return (
    <>
      <div className="sm:overflow-hidden mt-4 w-full h-[58rem] sm:h-[60rem] md:h-[40rem] lg:h-[50rem] md:flex lg:flex relative">
        <div className=" pl-4  md:w-[45%] lg:w-[45%] md:pl-8 lg:pl-16 md:flex md:flex-col  lg:flex lg:flex-col lg:gap-8">
          <div className="sm:pt-8 pb-4 lg:pb-8 lg:pt-16">
            <p className="sm:font-semibold text-sm md:text-3xl lg:text-4xl font-inter font-medium text-[#13505B]">
              Welcome To
            </p>
            <p className=" sm:font-semibold text-2xl md:text-4xl lg:text-5xl font-inter font-semibold text-[#13505B]">
              MasterOne
            </p>
          </div>
          <div className="">
            <ul className="list-disc pl-4 py-2">
              <li className="sm:font-semibold text-base md:text-lg lg:text-lg font-inter py-1 font-semibold text-[#13505B]">
                Don’t know which profession suits you ?
              </li>
              <li className="sm:font-semibold text-base md:text-lg lg:text-lg font-inter py-1 font-semibold text-[#13505B]">
                Can’t afford Rs 1.5 lakhs to upskill ?
              </li>
              <li className="sm:font-semibold text-base md:text-lg lg:text-lg font-inter py-1 font-semibold text-[#13505B]">
                Want to be master at your job ?
              </li>
            </ul>
          </div>
          <div className="sm:font-semibold text-base md:text-lg lg:text-xl">
            <a
              href="#"
              className="text-base md:text-lg font-inter lg:text-xl text-13505B underline font-semibold text-[#13505B]"
            >
              Download MasterOne app now !
            </a>

            <div className="flex w-[80%] gap-4 py-4">
              {isSmallScreen ? <Playstore w="150" /> : <Playstore />}
              {isSmallScreen ? <AppStore w="150" /> : <AppStore />}
              {/* <a
                href="https://play.google.com/store/apps/details?id=your.app.id"
                target="_blank"
                rel="noopener noreferrer"
              >
                {isSmallScreen ? <Playstore w="150" /> : <Playstore />}
              </a> */}
              {/* <a
                href="https://apps.apple.com/app/idyourAppId"
                target="_blank"
                rel="noopener noreferrer"
              >
                {isSmallScreen ? <AppStore w="150" /> : <AppStore />}
              </a> */}
            </div>
          </div>
          <p className="sm:font-semibold leading-normal text-base md:text-lg lg:text-lg  font-inter font-medium text-[#13505B]">
            MasterOne’s niche community guides students & professionals to
            discover and master their dream job role daily{" "}
            <br className="md:hidden lg:inline" />
            @Rs 199/month
          </p>
        </div>

        <div className="md:w-[55%] lg:w-[55%] lg:py-8">
          {isSmallScreen ? (
            <div className="flex justify-end items-center">
              <DesignComponent trans="1" h="500" w="500" />
            </div>
          ) : isMeidumScreen ? (
            <>
              <div className="flex justify-end md:-z-10">
                <DesignComponent trans="1" h="550" w="600" />
              </div>
            </>
          ) : isLargeScreen ? (
            <>
              <div className="hidden lg:block absolute lg:-right-16 lg:-z-10">
                <DesignComponent trans="1" h="650" w="700" />
              </div>
            </>
          ) : (
            <div className="hidden lg:block absolute lg:-right-16 lg:-z-10">
              <DesignComponent trans="1" h="750" w="738" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContentScreen;
