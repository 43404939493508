import React from "react";
import { ReactComponent as Facebook } from "../svgImages/Facebook.svg";
import { ReactComponent as Google } from "../svgImages/Google.svg";
import { ReactComponent as Twitter } from "../svgImages/Twitter.svg";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="h-auto bg-[#13505B] flex flex-col sm:flex-row justify-between px-2 lg:px-16">
      <div className="gap-4 py-4 sm:w-[40%] flex justify-around lg:py-8">
        <Link to="/privacy-policy" className="text-base text-white xl:text-xl">
          Privacy Policy
        </Link>
        <Link to="/terms" className="text-base text-white xl:text-xl">
          Terms
        </Link>
      </div>
      <div className="w-full sm:w-[70%] py-4 lg:w-[30%] flex flex-col gap-4 space-between lg:py-8  lg:gap-8 items-center sm:items-end lg:space-around ">
        {/* <div className="flex justify-end space-around lg:gap-8">
          <a
            href="https://twitter.com/yourusername"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
          <a
            href="https://facebook.com/yourusername"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
          <a
            href="https://plus.google.com/yourusername"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Google />
          </a>
        </div> */}

        <div>
          <p className="text-white">Copyright © 2024 | LNEBHARAT PVT LTD</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
