// import React from "react";

// function Policies() {
//   return (
//     <div className="p-4 ">
//       <p className="text-[#13505B] font-semibold text-xl">
//         Welcome to MasterOne! We are committed to protecting your privacy. This
//         Privacy Policy explains how we collect, use, and safeguard your personal
//         information when you use our mobile application.
//       </p>
//       <div>
//         <p>1. Information We Collect</p>
//         <div>
//           <p>
//             1A Personal Information: When you register an account or use certain
//             features of our app, we may collect your name, profile picture,
//             email address, and phone number, and details about your education
//             and work experience.
//           </p>
//           <p>
//             {" "}
//             Device Information: We automatically collect information about your
//             device, including device type, operating system version, and unique
//             device identifiers
//           </p>
//           <p>
//             {" "}
//             Usage Data: We gather data on how you interact with our app, such as
//             app usage statistics and user preferences.
//           </p>
//           <p>
//             {" "}
//             Location Information: With your consent, we may collect your precise
//             or approximate location information to help you find peers & mentors
//             nearby.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>How We Use Your Information</p>
//         <div>
//           <p> Provide and improve our product & services</p>
//           <p>Personalize your upskilling and networking roadmap</p>
//           <p>Analyze app usage trends and performance</p>
//           <p>Communicate with you about updates and promotions</p>
//         </div>
//       </div>
//       <div>
//         <p>3. Sharing of Your Information n</p>
//         <p>We may share your information with:</p>
//         <div>
//           <p>
//             Service Providers: Third-party service providers who assist us in
//             operating our app and delivering services.
//           </p>
//           <p>
//             Business Partners: Trusted partners who collaborate with us to offer
//             integrated services.
//           </p>
//           <p>
//             {" "}
//             Legal Compliance: When required by law or to protect our rights or
//             the rights of others.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>4. Your Rights and Choices</p>
//         <p>You have the right to:</p>
//         <div>
//           <p> Access and update your personal information</p>
//           <p>Opt out of certain data collection or processing activities</p>
//           <p>Delete your account and associated data</p>
//         </div>
//       </div>
//       <div>
//         <p>5. Data Security</p>

//         <div>
//           <p>
//             We implement security measures to protect your information from
//             unauthorized access, disclosure, alteration, or destruction.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>5. Data Retention</p>

//         <div>
//           <p>
//             We retain your information for as long as necessary to fulfill the
//             purposes outlined in this Privacy Policy unless a longer retention
//             period is required or permitted by law.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>6. Data Retention</p>

//         <div>
//           <p>
//             We retain your information for as long as necessary to fulfill the
//             purposes outlined in this Privacy Policy unless a longer retention
//             period is required or permitted by law.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>7. Children's Privacy</p>

//         <div>
//           <p>
//             Our app is not directed towards children under the age of 13, and we
//             do not knowingly collect personal information from children without
//             parental consent.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>8. Changes to This Privacy Policy</p>

//         <div>
//           <p>
//             We may update this Privacy Policy from time to time. We will notify
//             you of any changes by posting the new Privacy Policy on this page.
//           </p>
//         </div>
//       </div>
//       <div>
//         <p>9. Contact Us</p>

//         <div>
//           <p>
//             If you have any questions or concerns about our Privacy Policy or
//             practices, please contact us at support@masterone.app.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Policies;
import React from "react";
import Footer from "./Footer";

function Policies() {
  return (
    <>
      <div className="p-4 sm:text-sm md:text-base lg:text-lg">
        <p className="text-[#13505B] font-semibold sm:text-sm md:text-base lg:text-xl mb-4">
          Welcome to MasterOne! We are committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, and safeguard your
          personal information when you use our mobile application.
        </p>

        <div className="mb-8">
          <p className="font-semibold">1. Information We Collect</p>
          <div className="ml-4">
            <ul className="list-disc ml-8">
              <li>
                <span className="font-semibold">Personal Information:</span>{" "}
                When you register an account or use certain features of our app,
                we may collect your name, profile picture, email address, phone
                number, and details about your education and work experience.
              </li>
              <li>
                <span className="font-semibold">Device Information:</span> We
                automatically collect information about your device, including
                device type, operating system version, and unique device
                identifiers.
              </li>
              <li>
                <span className="font-semibold">Usage Data:</span> We gather
                data on how you interact with our app, such as app usage
                statistics and user preferences.
              </li>
              <li>
                <span className="font-semibold">Location Information:</span>{" "}
                With your consent, we may collect your precise or approximate
                location information to help you find peers & mentors nearby.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">2. How We Use Your Information</p>
          <div className="ml-4">
            <ul className="list-disc ml-8">
              <li>Provide and improve our product & services</li>
              <li>Personalize your upskilling and networking roadmap</li>
              <li>Analyze app usage trends and performance</li>
              <li>Communicate with you about updates and promotions</li>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">3. Sharing of Your Information</p>
          <div className="ml-4">
            <ul className="list-disc ml-8">
              <li>
                Service Providers: Third-party service providers who assist us
                in operating our app and delivering services.
              </li>
              <li>
                Business Partners: Trusted partners who collaborate with us to
                offer integrated services.
              </li>
              <li>
                Legal Compliance: When required by law or to protect our rights
                or the rights of others.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">4. Your Rights and Choices</p>
          <div className="ml-4">
            <ul className="list-disc ml-8">
              <li>Access and update your personal information</li>
              <li>
                Opt out of certain data collection or processing activities
              </li>
              <pli>Delete your account and associated data</pli>
            </ul>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">5. Data Security</p>
          <div className="ml-4">
            <p>
              We implement security measures to protect your information from
              unauthorized access, disclosure, alteration, or destruction.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">6. Data Retention</p>
          <div className="ml-4">
            <p>
              We retain your information for as long as necessary to fulfill the
              purposes outlined in this Privacy Policy unless a longer retention
              period is required or permitted by law.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">7. Children's Privacy</p>
          <div className="ml-4">
            <p>
              Our app is not directed towards children under the age of 13, and
              we do not knowingly collect personal information from children
              without parental consent.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <p className="font-semibold">8. Changes to This Privacy Policy</p>
          <div className="ml-4">
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page.
            </p>
          </div>
        </div>

        <div>
          <p className="font-semibold">9. Contact Us</p>
          <div className="ml-4">
            <p>
              If you have any questions or concerns about our Privacy Policy or
              practices, please contact us at support@masterone.app.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Policies;
